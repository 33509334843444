import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <p>Kohinoor plaza 1, Faisalabad, Pakistan | Email: Khelo2k24@gmail.com</p>
      <p>Copyright © 2024 | Powered By: webfixinc.com</p>
    </div>
  );
};

export default Footer;
