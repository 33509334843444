import { useState } from "react";
import logo from "../../images/khelo-logo.png";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import youtube from "../../images/youtube.svg";
import tiktok from "../../images/tiktok.svg";
import { scrollToSection } from "../utils/helper";

import "./header.scss";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="headerContainer">
      <div className="header">
        <div className="logo">
          <img src={logo} alt="khelo" />
        </div>
        <div className="list">
          <ul>
            <li onClick={() => scrollToSection("home")}>Home</li>
            <li onClick={() => scrollToSection("about")}>About Us</li>
            <li onClick={() => scrollToSection("faq")}>FAQ</li>
            <li onClick={() => scrollToSection("contact")}>Contact Us</li>
          </ul>
        </div>
        <div className={"list-mobile " + (menuOpen && "active")}>
          <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
        <div className={"list-mobile-menu " + (menuOpen && "active")}>
          <ul>
            <li
              onClick={() => {
                scrollToSection("home");
                setMenuOpen(false);
              }}
            >
              Home
            </li>
            <li
              onClick={() => {
                scrollToSection("about");
                setMenuOpen(false);
              }}
            >
              About Us
            </li>
            <li
              onClick={() => {
                scrollToSection("faq");
                setMenuOpen(false);
              }}
            >
              FAQ
            </li>
            <li
              onClick={() => {
                scrollToSection("contact");
                setMenuOpen(false);
              }}
            >
              Contact Us
            </li>
          </ul>
        </div>
        <div className="icons">
          <a
            href="https://www.facebook.com/people/Khelo/61556861052416/?mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.instagram.com/khelo_pk/?igsh=MXdqd3c5eWYyZDRzNw%3D%3D"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram" />
          </a>
          <a
            href="https://www.youtube.com/@khelo_pk"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtube} alt="youtube" className="youtube" />
          </a>
          <a
            href="https://www.tiktok.com/@khelo_pk?_t=8pR7211nY8X&_r=1"
            target="_blank"
            rel="noreferrer"
          >
            <img src={tiktok} alt="youtube" className="youtube" />
          </a>
        </div>
      </div>
      <div className="small-screen-icons">
        <a
          href="https://www.facebook.com/people/Khelo/61556861052416/?mibextid=ZbWKwL"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="facebook" />
        </a>
        <a
          href="https://www.instagram.com/khelo_pk/?igsh=MXdqd3c5eWYyZDRzNw%3D%3D"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="instagram" />
        </a>
        <a
          href="https://www.youtube.com/@khelo_pk"
          target="_blank"
          rel="noreferrer"
        >
          <img src={youtube} alt="youtube" className="youtube" />
        </a>
        <a
          href="https://www.tiktok.com/@khelo_pk?_t=8pR7211nY8X&_r=1"
          target="_blank"
          rel="noreferrer"
        >
          <img src={tiktok} alt="youtube" className="youtube" />
        </a>
      </div>
    </div>
  );
};

export default Header;
