import { useEffect, useState } from "react";
import "./accordian.scss";

const Accordian = ({ items, keepOtherOpen }) => {
  const [accordianItems, setAccordianItems] = useState(null);

  useEffect(() => {
    if (items) {
      setAccordianItems([
        ...items.map((item) => ({
          ...item,
          toggled: false,
        })),
      ]);
    }
  }, [items]);

  const handleAccordianToggle = (clickedItem) => {
    setAccordianItems([
      ...accordianItems.map((item) => {
        let toggled = item.toggled;

        if (clickedItem.id === item.id) {
          toggled = !item.toggled;
        } else if (!keepOtherOpen) {
          toggled = false;
        }

        return {
          ...item,
          toggled,
        };
      }),
    ]);
  };

  return (
    <div className="accordianContainer">
      {accordianItems?.map((listItem) => {
        return (
          <div
            className={`accordian ${listItem.toggled ? "toggled" : ""}`}
            key={listItem.id}
          >
            <button
              className="toggle"
              onClick={() => handleAccordianToggle(listItem)}
            >
              <p>{listItem.label}</p>
              <div className="direction">{listItem.toggled ? "-" : "+"}</div>
            </button>
            {listItem.toggled && (
              <div className={`content-data ${listItem.toggled ? "open" : ""}`}>
                <div className="content">{listItem.renderContent()}</div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Accordian;
