import Accordian from "../accordian/Accordian";
import "./questions.scss";

const data = [
  {
    id: 0,
    label: "What is a Khelo App?",
    renderContent: () => (
      <p>
        Khelo App is a free Android App (iOS version coming soon). You can sign
        up for free and start playing interesting games, solve Quizzes and win
        exciting prizes Weekly and Monthly.
      </p>
    ),
  },
  {
    id: 1,
    label: "How can I win the prizes?",
    renderContent: () => (
      <p>
        Everyone playing is eligible for the prizes. We will select the top
        players, and they will have the chance to win prizes twice a month.
      </p>
    ),
  },
  {
    id: 2,
    label: "How do I play?",
    renderContent: () => (
      <p>
        Sign up to get the code, or simply contact our partners to obtain it.
        The code will allow you to play various games.
      </p>
    ),
  },
  {
    id: 3,
    label: "How do I extract my winnings?",
    renderContent: () => (
      <p>
        Our team will contact the winners and the reward will be transferred
        through any of the methods according to the winner’s convenience.
        Alternatively, the winner can collect prize directly from our head
        office.
      </p>
    ),
  },
];

const Questions = () => {
  return (
    <div className="questionsContainer" id="faq">
      <div className="questions">
        <h2>Frequently asked questions</h2>
        <div className="questionsAccord">
          <Accordian items={data} keepOtherOpen={false} />
        </div>
      </div>
    </div>
  );
};

export default Questions;
