// import Challenge from "../../components/challenge/Challenge";
import ContactForm from "../../components/contactForm/ContactForm";
import ContactUs from "../../components/contactUs/ContactUs";
import DownloadApp from "../../components/downloadApp/DownloadApp";
import Footer from "../../components/footer/Footer";
import Front from "../../components/front/Front";
import GamesList from "../../components/gamesList/GamesList";
import Header from "../../components/header/Header";
import PlayToWin from "../../components/playToWin/PlayToWin";
import Questions from "../../components/questions/Questions";

const Home = () => {
  return (
    <div>
      <Header />
      <Front />
      {/* <Challenge /> */}
      <DownloadApp />
      <GamesList />
      <PlayToWin />
      <Questions />
      <ContactForm />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
