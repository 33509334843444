import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./contactForm.scss";

const ContactForm = () => {
  const [emailSend, setEmailSend] = useState(false);
  const [emailNotSend, setEmailNotSend] = useState(false);
  const formRef = useRef();
  const handleSendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_gq7ecqr",
        "template_zw8e8kv",
        formRef.current,
        "JAbvGDEhsfbrtUnVO"
      )
      .then((res) => {
        console.log("res", res);
        setEmailSend(true);
        formRef.current.reset();
        setTimeout(() => {
          setEmailSend(false);
        }, 5000);
      })
      .catch((err) => {
        console.log("err", err);
        setEmailNotSend(true);
        setTimeout(() => {
          setEmailNotSend(false);
        }, 5000);
      });
  };

  return (
    <div className="contactFormContainer">
      <div className="contactForm">
        <div className="contactFormLeft">
          <div className="contactFormLeftAlign">
            <h2>
              Join our dynamic team and be part of the exciting journey at
              Khelo.pk!
            </h2>
            <p>
              To become our channel partner manager, please reach out to us
              directly or just fill the form.
            </p>
            <p>Kohinoor Plaza 1, Faisalabad, Pakistan</p>
            <p>Khelo2k24@gmail.com</p>
          </div>
        </div>
        <div className="contactFormRight">
          <div className="contactFormRightAlign">
            <form ref={formRef} onSubmit={handleSendEmail}>
              <label>Your Name</label>
              <input
                required
                type="text"
                name="name"
                placeholder="Enter Your Name *"
              />
              <label>Your Email</label>
              <input
                required
                type="text"
                name="email"
                placeholder="Enter Your Email *"
              />
              <label>Your Message</label>
              <textarea
                name="message"
                placeholder="Enter Your Message"
                rows={10}
              ></textarea>
              <button type="submit">SEND</button>
            </form>
            <span>{emailSend && "Email Successfully Send"}</span>
            <span>{emailNotSend && "Email Not Send"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
