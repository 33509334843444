import mobile from "../../images/front-1.png";
import googlePlay from "../../images/google-play.svg";

import "./downloadApp.scss";

const DownloadApp = () => {
  return (
    <div className="downloadContainer" id="about">
      <div className="download">
        <div className="downloadDisplay">
          <div className="downloadLeft">
            <h1>Where Fun Meets Rewards</h1>
            <div className="hr-line"></div>
            <p>
              At khelo, we're passionate about creating an exhilarating gaming
              experience that transcends mere entertainment. Our platform is
              designed to delight players with a fusion of skill-based games,
              adrenaline pumping challenges, and the thrill of winning exciting
              prizes.
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.khelo.khelocustomer"
              target="_blank"
              rel="noreferrer"
            >
              <img src={googlePlay} alt="play-store" />
              Download for Android
            </a>
          </div>
          <div className="downloadRight">
            <img src={mobile} alt="display" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
