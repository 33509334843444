import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import youtube from "../../images/youtube.svg";
import tiktok from "../../images/tiktok.svg";

import "./contactUs.scss";

const ContactUs = () => {
  return (
    <div className="contactUsContainer" id="contact">
      <div className="contactUs">
        <h2>Contact Us</h2>
        <div className="contactUsData">
          <div className="dataLeft">
            <div className="dataLeftAlign">
              <h3>We're Ready, Let's Talk.</h3>
              <p>
                Want to know more about us or are facing issues regarding the
                app? Contact us or our partners and we will be happy to help.
              </p>
            </div>
          </div>
          <div className="dataRight">
            <h3>Contact Info</h3>
            <div>
              <h4>Address</h4>
              <p>Kohinoor plaza 1, Faisalabad, Pakistan</p>
            </div>
            <div>
              <h4>Email Us</h4>
              <p>Khelo2k24@gmail.com</p>
            </div>
            <div>
              <h4>Follow Us</h4>
              <div className="icons">
                <a
                  href="https://www.facebook.com/people/Khelo/61556861052416/?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} alt="facebook" />
                </a>
                <a
                  href="https://www.instagram.com/khelo_pk/?igsh=MXdqd3c5eWYyZDRzNw%3D%3D"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="instagram" />
                </a>
                <a
                  href="https://www.youtube.com/@khelo_pk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={youtube} alt="youtube" className="youtube" />
                </a>
                <a
                  href="https://www.tiktok.com/@khelo_pk?_t=8pR7211nY8X&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={tiktok} alt="youtube" className="youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
