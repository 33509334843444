import downloadApp from "../../images/downloadApp.png";
import googlePlay from "../../images/google-play.svg";
import ios from "../../images/ios-apple.svg";

import "./front.scss";

const Front = () => {
  return (
    <div className="frontContainer" id="home">
      <div className="front">
        <div className="frontDisplay">
          <div className="displayLeft">
            <h6>Welcome to Khelo App!</h6>
            <h1>Dive into the Thrill of Gaming and Winning at Khelo!!</h1>
            <p>
              Lets dive into a world of engaging games, challenging quizzes, and
              the chance to win thrilling prizes every week and month!
            </p>
            <div className="buttons">
              <a
                href="https://play.google.com/store/apps/details?id=com.khelo.khelocustomer"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googlePlay} alt="play-store" />
                Download for Android
              </a>
              <button>
                <img src={ios} alt="ios" />
                Download for IOS
              </button>
            </div>
          </div>
          <div className="displayRight">
            <img src={downloadApp} alt="display" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Front;
