import playToWin1 from "../../images/playtowin1.png";
import playToWin2 from "../../images/playtowin2.jpg";
import playToWin3 from "../../images/playtowin3.png";
import playToWin4 from "../../images/playtowin4.png";

import "./playToWin.scss";

const PlayToWin = () => {
  return (
    <div className="playToWinContainer">
      <div className="playToWin">
        <div className="playToWinContent">
          <div className="playToWinContentLeft">
            <h1>Play to win!</h1>
            <div className="hr-line"></div>
            <p>
              Everyone who joins in can win cool prizes! We pick the best
              players twice a month to score big!
            </p>
          </div>
          <div className="playToWinContentRight">
            <div className="playToWinContentImgSec1">
              <img src={playToWin1} alt="win-1" className="image1" />
              <img src={playToWin2} alt="win-2" className="image2" />
            </div>
            <div className="playToWinContentImgSec2">
              <img src={playToWin3} alt="win-3" className="image3" />
              <img src={playToWin4} alt="win-4" className="image4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayToWin;
