// import gameOne from "../../images/game.png";
import gameOne from "../../images/s1.jpg";
import gameTwo from "../../images/s2.jpg";
import gameThree from "../../images/s3.jpg";

import "./gamesList.scss";

const GamesList = () => {
  return (
    <div className="gamesContainer">
      <div className="games">
        <div className="game">
          <img src={gameOne} alt="game-1" />
        </div>
        <div className="game">
          <img src={gameTwo} alt="game-2" />
        </div>
        <div className="game">
          <img src={gameThree} alt="game-3" />
        </div>
      </div>
    </div>
  );
};

export default GamesList;
